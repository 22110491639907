


































































































import Vue from 'vue'
import Component from 'vue-class-component';
import RegionalPositivesTrends from '@/components/regional-data/charts/RegionalPositivesTrends.vue';
import RegionalDeathsTrends from '@/components/regional-data/charts/RegionalDeathsTrends.vue';

import DailyTrendsChart from '@/components/charts/DailyTrendsChart.vue';
import RatioTrendChart from '@/components/charts/RatioTrendChart.vue';
import TamponTestChart from '@/components/charts/TamponTestChart.vue';
import RegionComparisonPieChart from '@/components/charts/RegionComparisonPieChart.vue';

import {Prop} from 'vue-property-decorator';

@Component({components: {
        DailyTrendsChart,
        RatioTrendChart,
        TamponTestChart,
        RegionalPositivesTrends,
        RegionalDeathsTrends,
        RegionComparisonPieChart
    }})
export default class RegionalData extends Vue{

    private selectedRegion = "Lombardia"

    private get sortedRegionNames(): string[] {
        return this.regionNames.sort()
    }

    @Prop()
    protected regionalTrend!: any;

    @Prop()
    protected regionNames!: string[];

    @Prop()
    private nationalTrend!: string[]

}
