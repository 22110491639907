













import Vue from 'vue'
import Component from 'vue-class-component';
import CasesCounter from '@/components/header/CasesCounter.vue';
import {Prop} from 'vue-property-decorator';

@Component({components: {CasesCounter}})
export default class Header extends Vue{

    @Prop()
    protected nationalTrend!: any;

}
