












































import Vue from 'vue'
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import NationComparisonChart from '@/components/charts/NationComparisonChart.vue';

@Component({components: {
    NationComparisonChart
    }})
export default class WorldData extends Vue{

    @Prop()
    private worldData!: {[key: string]: any}[];

    private datePattern = new RegExp('[0-9]{1,2}/[0-9]{1,2}/[0-9]{2}')

    private euAxisType = "Scala Logaritmica"
    private worldAxisType = "Scala Logaritmica"

    private wantedEUCountries: {province: string; country: string}[] = [
        {province: '', country: 'Italy'},
        {province: '', country: 'Spain'},
        {province: '', country: 'Switzerland'},
        {province: '', country: 'France'},
        {province: '', country: 'Germany'},
        {province: '', country: 'United Kingdom'},
        {province: '', country: 'Netherlands'},
    ]

    private wantedWorldCountries: {province: string; country: string}[] = [
        {province: '', country: 'Italy'},
        {province: '', country: 'Iran'},
        {province: '', country: 'Korea, South'},
        {province: '', country: 'US'},
    ]

    private get wantedEUData() {
        return this.worldDataFiltered(this.wantedEUCountries)
    }

    private get wantedWorldData() {
        const wantedWorldData: any[] = []
        wantedWorldData.push(this.aggregateChineseData())
        wantedWorldData.push(...this.worldDataFiltered(this.wantedWorldCountries))
        return wantedWorldData
    }

    private get EUDataLabels() {
        const maxDays = Math.max(...this.wantedEUData.map((entry) => {
            const data = entry['data'] as number[]
            return data.length
        }))
        return Array.from(Array(maxDays).keys()).map((value) => {
            return "Day " + (value+1)
        })
    }

    private get WorldDataLabels() {
        const maxDays = Math.max(...this.wantedWorldData.map((entry) => {
            const data = entry['data'] as number[]
            return data.length
        }))
        return Array.from(Array(maxDays).keys()).map((value) => {
            return "Day " + (value+1)
        })
    }

    private worldDataFiltered (wantedCountries: {province: string; country: string}[]) {
        const filteredList: {[key: string]: any}[] = []

        const isWantedCountry= function(
            worldDataEntry: {[key: string]: any},
            wantedCountries: {country: string; province: string}[]
            ) {
            for(const i in wantedCountries) {
                if (
                    wantedCountries[i]['country'] === worldDataEntry['Country/Region'] &&
                        wantedCountries[i]['province'] === worldDataEntry['Province/State']
                ) return true;
            }
            return false;
        }

        this.worldData.map((worldDataEntry) => {
            if(isWantedCountry(worldDataEntry, wantedCountries)) {
                const columnsToClean = Object.keys(worldDataEntry).filter((key: string) => {
                    return key.match(this.datePattern)
                })
                const casesCounter: number[] = []
                columnsToClean.map((column) => {
                    if (worldDataEntry[column] > 100) {
                        casesCounter.push(parseInt(worldDataEntry[column] as string))
                    }
                })

                filteredList.push({
                    country: worldDataEntry['Country/Region'],
                    data: casesCounter.sort((a,b) => { return a-b })
                })
            }
        })

        return filteredList
    }

    private aggregateChineseData() {
        const chinaData = this.worldData.filter((entry) => { return entry['Country/Region'] === 'China' })
        const dateAggregates: {[key: string]: number} = {}

        for (const chinaEntry of chinaData) {
            const dateColumns = Object.keys(chinaEntry).filter((key) => { return this.datePattern.test(key) })
            dateColumns.map((dateColumn) => {
                if (!dateAggregates[dateColumn]) {
                    dateAggregates[dateColumn] = 0
                }
                dateAggregates[dateColumn] += parseInt(chinaEntry[dateColumn], 10)
            })
        }

        const chineseData = Object.keys(dateAggregates).map((dateColumn) => {
            return dateAggregates[dateColumn]
        }).filter((casesCount) => { return casesCount > 100 }).sort((a,b) => { return a-b})

        return {
            country: "China",
            data: chineseData
        }
    }

}
