





















































































import Vue from 'vue'
import Component from 'vue-class-component';
import CasesCounter from '@/components/header/CasesCounter.vue';
import DailyTrendsChart from '@/components/charts/DailyTrendsChart.vue';
import DailyChangesChart from '@/components/charts/DailyChangesChart.vue';
import DailyChangesVsBackwardsAverageChart from '@/components/charts/DailyChangesVsBackwardsAverageChart.vue';
import RatioTrendChart from '@/components/charts/RatioTrendChart.vue';
import DailyIncreasePaceChart from '@/components/charts/DailyIncreasePaceChart.vue';
import TamponTestChart from '@/components/charts/TamponTestChart.vue';

import {Getter} from 'vuex-class';
import {Prop} from 'vue-property-decorator';

@Component({components: {
        RatioTrendChart,
        DailyTrendsChart,
        DailyChangesChart,
        DailyChangesVsBackwardsAverageChart,
        DailyIncreasePaceChart,
        CasesCounter,
        TamponTestChart
    }})
export default class NationalData extends Vue{

    @Prop()
    private nationalTrend!: string[]

    private get chartsInputData(): {[key: string]: any[]} {
        const series: {[key: string]: any[]} = {'data':[]}

        this.nationalTrend.map((element: any) => {
            Object.keys(element).map((key: string) => {
                if (!series[key]) series[key] = []
                series[key].push(element[key])
            })
        })

        return series
    }
}
