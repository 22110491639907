




import Vue from 'vue'
import Component from 'vue-class-component';

import * as echarts from 'echarts'
import ECharts = echarts.ECharts;
import {Prop, Watch} from 'vue-property-decorator';
import Series = echarts.EChartOption.Series;

import ChartUtils from '@/components/charts/ChartUtils';

@Component
export default class SingleTrendWithMarkPointChart extends Vue{

    private trendsChart!: ECharts;

    @Prop()
    private chartSeriesColor!: string

    @Prop()
    private chartLegend!: string

    @Prop()
    private seriesName!: string

    @Prop()
    private inputData!: {[key: string]: any[]}

    @Prop()
    private chartId!: string

    @Prop()
    private chartTitle!: string

    @Watch('inputData')
    private onDataChange() {
        this.initChart()
    }

    public mounted() {
        const chartDiv = document.getElementById(this.chartId) as HTMLDivElement
        this.trendsChart = echarts.init(chartDiv)
        this.initChart()
        return 0
    }

    private initChart() {

        const key = this.chartLegend

        const chartData = this.inputData[key].map((element: number, index: number) => {
            if (index > 0) {
                return parseFloat((100*(element - this.inputData[key][index-1])/this.inputData[key][index-1]).toFixed(2))
            }
        }).slice(1)

        const chartSeries: Series[] = [{
            symbol: 'circle',
            symbolSize: ChartUtils.getSymbolSize,
            smooth: 0.1,
            type: 'line',
            name: this.seriesName,
            stack: this.seriesName,
            data: chartData,
            lineStyle: {
                width: ChartUtils.getLineWidth
            },
            markPoint: {
                symbol: 'pin',
                symbolSize: 80,
                data: [
                    {coord: [chartData.length-1, chartData[chartData.length-1]], value: chartData[chartData.length-1]}
                ]
            }
        }]

        this.trendsChart.setOption({
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: [this.seriesName]
            },
            color: [this.chartSeriesColor],
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: this.inputData['data'].slice(1).map((date: string) => { return date.split('T')[0]})
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value} %'
                }
            },
            series: chartSeries
        })

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        window.addEventListener('resize', function() {
            vm.trendsChart.resize()
        })
    }
}
