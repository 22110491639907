




import Vue from 'vue'
import Component from 'vue-class-component';
import {Getter} from 'vuex-class';

import * as echarts from 'echarts'
import ECharts = echarts.ECharts;
import {Prop, Watch} from 'vue-property-decorator';
import Series = echarts.EChartOption.Series;
import ChartUtils from '@/components/charts/ChartUtils';

@Component
export default class TamponTestChart extends Vue{

    private trendsChart!: ECharts;

    @Prop()
    private chartLegendData!: string[]

    @Prop()
    private chartLegendNames!: string[]

    @Prop()
    private inputData!: {[key: string]: any[]}

    @Prop()
    private chartId!: string

    @Watch('inputData')
    private onDataChange() {
        this.initChart()
    }


    public mounted() {
        const chartDiv = document.getElementById(this.chartId) as HTMLDivElement
        this.trendsChart = echarts.init(chartDiv)
        this.initChart()
        return 0
    }

    private initChart() {

        const chartSeries: Series[] = this.chartLegendData.slice(0,2).map((key: string, index: number) => {
            return {
                yAxisIndex: 0,
                symbol: 'circle',
                symbolSize: ChartUtils.getSymbolSize,
                smooth: 0.1,
                type: 'bar',
                name: this.chartLegendNames[index],
                stack: this.chartLegendNames[index],
                data: this.inputData[key].map((element: number, index: number) => {
                    if (index > 0) {
                        return element - this.inputData[key][index-1]
                    }
                }).slice(1),
                lineStyle: {
                    width: ChartUtils.getLineWidth
                }
            } as Series
        })

        const percSeries: Series = {
            yAxisIndex: 1,
            symbol: 'circle',
            symbolSize: ChartUtils.getSymbolSize,
            smooth: true,
            type: 'line',
            name: this.chartLegendNames[2],
            stack: this.chartLegendNames[2],
            data: this.inputData['totale_casi'].map((element: number, index: number) => {
                if (index > 0) {
                    const totaleCasi = element - this.inputData[this.chartLegendData[1]][index-1]
                    const tamponi = this.inputData[this.chartLegendData[0]][index] - this.inputData[this.chartLegendData[0]][index-1]
                    return tamponi === 0 ? 0 : parseFloat(((totaleCasi / tamponi) * 100).toFixed(2))
                }
            }).slice(1),
            lineStyle: {
                width: ChartUtils.getLineWidth
            }
        }

        chartSeries.push(percSeries)

        this.trendsChart.setOption({
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: this.chartLegendNames
            },
            color: ['#ffdd57', '#ff3860', '#363636'],
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: this.inputData['data'].slice(1).map((date: string) => { return date.split('T')[0]})
            },
            yAxis: [{
                type: 'value',
                min: 0
            },{
                type: 'value',
                min: 0
            }],
            series: chartSeries
        })

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        window.addEventListener('resize', function() {
            vm.trendsChart.resize()
        })
    }
}
