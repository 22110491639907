
















import Vue from 'vue'
import Component from 'vue-class-component';
import ProvincialDataTable from '@/components/provincial-data/tables/ProvincialDataTable.vue';
import {Getter} from 'vuex-class';
import {Prop} from 'vue-property-decorator';

@Component({components: {ProvincialDataTable}})
export default class ProvincialData extends Vue{
    @Prop()
    protected provincialData!: any;
}
