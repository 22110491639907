export default class ChartUtils {
    public static get getLineWidth(): number {
        return 3
    }

    public static get getSymbolSize(): number {
        return 6
    }

    public static get getSerie5Colors(): string[] {
        return [
            '#ff3860',
            '#ed6c28',
            '#c49500',
            '#90b330',
            '#48c774',
        ]
    }

    public static get getSerie6Colors(): string[] {
        return [
            '#ff3860',
            '#f36333',
            '#d68603',
            '#b1a206',
            '#84b73d',
            '#48c774',
        ]
    }
}
