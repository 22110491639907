




import Vue from 'vue'
import Component from 'vue-class-component';
import {Getter} from 'vuex-class';

import * as echarts from 'echarts'
import ECharts = echarts.ECharts;
import Series = echarts.EChartOption.Series;
import ChartUtils from '@/components/charts/ChartUtils';

@Component
export default class RegionalPositivesTrends extends Vue{

    private trendsChart!: ECharts;

    private wantedRegions: string[] = [
        'Lombardia',
        'Veneto',
        'Piemonte',
        'Emilia-Romagna',
        'Marche'
    ]

    @Getter('getRegionalTrend')
    protected getRegionalTrend!: any;

    @Getter('getNationalTrend')
    protected getNationalTrend!: any;

    @Getter('getRegionNames')
    protected getRegionNames!: string[];

    public mounted() {
        const chartDiv = document.getElementById('regionalpositivetrendschart') as HTMLDivElement
        this.trendsChart = echarts.init(chartDiv)
        this.initChart()
        return 0
    }

    private initChart() {

        const chartSeries: Series[] = this.wantedRegions.map((region: string) => {
            return {
                type: 'bar',
                stack: 'positivi_regionali',
                name: region,
                data: this.getRegionalTrend[region]['totale_casi']
            } as Series
        })

        this.trendsChart.setOption({
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: this.getRegionNames
            },
            color: [
                '#ff3860',
                '#ff6a7d',
                '#ff919a',
                '#ffb4b9',
                '#ffd5d7',
            ],
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: this.getRegionalTrend[this.getRegionNames[0]]['data'].map((str: string) => { return str.split('T')[0]})
            },
            yAxis: {
                type: 'value'
            },
            series: chartSeries
        })

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        window.addEventListener('resize', function() {
            vm.trendsChart.resize()
        })
    }
}
