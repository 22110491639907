




import Vue from 'vue'
import Component from 'vue-class-component';

import * as echarts from 'echarts'
import ECharts = echarts.ECharts;
import {Prop, Watch} from 'vue-property-decorator';
import Series = echarts.EChartOption.Series;

import ChartUtils from '@/components/charts/ChartUtils';

@Component
export default class RegionComparisonPieChart extends Vue{

    private trendsChart!: ECharts;

    @Prop()
    private chartLegendData!: string

    @Prop()
    private chartLegendName!: string

    @Prop()
    private inputData!: {[key: string]: {[key: string]: any[]}}

    @Prop()
    private chartId!: string


    @Watch('inputData')
    private onDataChange() {
        this.initChart()
    }

    public mounted() {
        const chartDiv = document.getElementById(this.chartId) as HTMLDivElement
        this.trendsChart = echarts.init(chartDiv)
        this.initChart()
        return 0
    }

    private initChart() {

        const chartSeries: Series[] = []

        const valueByRegion = Object.keys(this.inputData).map((region: string) => {

            const lastIndex = this.inputData[region][this.chartLegendData].length - 1
            const penultimateIndex = this.inputData[region][this.chartLegendData].length -2

            const valueDelta = this.inputData[region][this.chartLegendData][lastIndex] -
                this.inputData[region][this.chartLegendData][penultimateIndex]
            return {name: region, value: valueDelta}
        })

        valueByRegion.sort(function(p1, p2) { return p2.value - p1.value; });

        chartSeries.push({
            type: 'pie',
            data: valueByRegion,
            radius: '55%'
        } as Series)

        this.trendsChart.setOption({
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)'
            },

            //color: ['#ff3860', '#23d160', '#363636'],
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            series: chartSeries
        })

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        window.addEventListener('resize', function() {
            vm.trendsChart.resize()
        })
    }
}
