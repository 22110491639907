












































import Vue from 'vue'
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class CasesCounter extends Vue{
    @Prop()
    protected nationalTrend!: any;

    private wantedTrends: string[] = ['totale_positivi', 'deceduti', 'dimessi_guariti', 'totale_casi']

    private get latestData(): number {
        return this.nationalTrend[this.nationalTrend.length - 1]
    }

    private get increases() {
        const increases: {[key: string]: number} = {}
        this.wantedTrends.map((key: string) => {
            increases[key] = this.nationalTrend[this.nationalTrend.length - 1][key] -
                this.nationalTrend[this.nationalTrend.length - 2][key]
        })
        return increases
    }

    private get deltaText() {
        const texts: {[key: string]: string} = {}

        this.wantedTrends.map((key: string) => {
            const sign = this.increases[key] > 0 ? '+' : '-'
            texts[key] = sign + ' ' + Math.abs(this.increases[key])
        })

        return texts
    }
}
