

































import Vue from 'vue'
import Component from 'vue-class-component';
import Header from '@/components/header/Header.vue';
import NationalData from '@/components/national-data/NationalData.vue';
import RegionalData from '@/components/regional-data/RegionalData.vue';
import ProvincialData from '@/components/provincial-data/ProvincialData.vue';
import WorldData from '@/components/world-data/WorldData.vue';

import {Action, Getter} from 'vuex-class';

@Component({components: {Header, NationalData, RegionalData, ProvincialData, WorldData}})
export default class Home extends Vue{

  @Action('loadNationalTrend')
  protected loadNationalTrend!: any;

  @Action('loadRegionalTrend')
  protected loadRegionalTrend!: any;

  @Action('loadProvincialData')
  protected loadProvincialData!: any;

  @Action('loadWorldData')
  protected loadWorldData!: any;

  @Getter('getNationalTrend')
  protected getNationalTrend!: any;

  @Getter('getRegionalTrend')
  protected getRegionalTrend!: any;

  @Getter('getRegionNames')
  protected getRegionNames!: any;

  @Getter('getProvincialData')
  protected getProvincialData!: any;

  @Getter('getWorldData')
  protected getWorldData!: any;

  private get isReady(): boolean {
    return this.getNationalTrend
        && this.getRegionalTrend
        && this.getRegionNames
        && this.getRegionalTrend
        && this.getProvincialData
        && this.getWorldData
  }

  public created() {
    this.loadNationalTrend()
    this.loadRegionalTrend()
    this.loadProvincialData()
    this.loadWorldData()
  }

}
