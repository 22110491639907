




import Vue from 'vue'
import Component from 'vue-class-component';

import * as echarts from 'echarts'
import ECharts = echarts.ECharts;
import {Prop, Watch} from 'vue-property-decorator';
import Series = echarts.EChartOption.Series;

import ChartUtils from '@/components/charts/ChartUtils';

@Component
export default class DailyChangesVsBackwardsAverageChart extends Vue{

    private trendsChart!: ECharts;

    @Prop()
    private numberBackwardPoints!: number

    @Prop()
    private chartLegendData!: string

    @Prop()
    private chartLegendName!: string

    @Prop()
    private inputData!: {[key: string]: any[]}

    @Prop()
    private chartId!: string

    @Watch('inputData')
    private onDataChange() {
        this.initChart()
    }

    public mounted() {
        const chartDiv = document.getElementById(this.chartId) as HTMLDivElement
        this.trendsChart = echarts.init(chartDiv)
        this.initChart()
        return 0
    }

    private initChart() {

        const chartSeries: Series[] = []

        const backwardsAverageName = 'Media Mobile ' + this.chartLegendName + ' (' + this.numberBackwardPoints + ' giorno/i)'

        const trendData = this.inputData[this.chartLegendData].map((element: number, index: number) => {
            if (index > 0) {
                return element - this.inputData[this.chartLegendData][index-1]
            }
            else return -1
        }).slice(1)

        const backwardsAverageData = trendData.map((value: number, index: number, array: number[]) => {
            const dataPointValues = array.slice(Math.max(0, index - this.numberBackwardPoints), index)
            if (dataPointValues.length == 0)
                return 0
            else return parseFloat(
                (dataPointValues.reduce((sum, current) => sum + current) / dataPointValues.length).toFixed(2)
            )
        })

        chartSeries.push({
            symbol: 'circle',
            symbolSize: ChartUtils.getSymbolSize,
            smooth: 0.1,
            type: 'line',
            name: this.chartLegendName,
            stack: this.chartLegendName,
            data: trendData,
            lineStyle: {
                width: ChartUtils.getLineWidth
            }
        } as Series)

        chartSeries.push({
            symbol: 'circle',
            symbolSize: ChartUtils.getSymbolSize,
            smooth: true,
            type: 'line',
            name: backwardsAverageName,
            stack: backwardsAverageName,
            data: backwardsAverageData,
            lineStyle: {
                width: ChartUtils.getLineWidth
            }
        } as Series)

        this.trendsChart.setOption({
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: [this.chartLegendName, backwardsAverageName]
            },
            color: ['#ff3860', '#363636'],
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: this.inputData['data'].slice(1).map((date: string) => { return date.split('T')[0]})
            },
            yAxis: {
                type: 'value'
            },
            series: chartSeries
        })

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        window.addEventListener('resize', function() {
            vm.trendsChart.resize()
        })
    }
}
