













import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from 'vue-property-decorator';

@Component
export default class DailyChangesVsBackwardsAverageChart extends Vue{

    private currentPage = 1

    @Prop()
    private inputData!: {[key: string]: string|number}[]

    private columns = [{
            field: 'denominazione_regione',
            label: 'Regione',
            searchable: true
        },{
            field: 'denominazione_provincia',
            label: 'Provincia',
            searchable: true
        }, {
            field: 'totale_casi',
            label: 'Totale Casi',
            numeric: true,
            sortable: true
        }]
}
