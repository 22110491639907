import { render, staticRenderFns } from "./RatioTrendChart.vue?vue&type=template&id=70f3a56c&scoped=true&"
import script from "./RatioTrendChart.vue?vue&type=script&lang=ts&"
export * from "./RatioTrendChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f3a56c",
  null
  
)

export default component.exports