import Vue from 'vue'
import Vuex from 'vuex'
import axios, {AxiosResponse} from 'axios'

Vue.use(Vuex)

import * as Papa from 'papaparse'

const GITHUB_ITALIAN_DATA_BASE_URL = 'https://raw.githubusercontent.com/pcm-dpc/COVID-19/master/'
const GITHUB_WORLD_DATA_BASE_URL = 'https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/'

export default new Vuex.Store({
  state: {
    nationalTrend: null,
    regionalTrend: null,
    regionNames: null,
    provincialData: null,
    worldData: null,
    trendColors: {
      'deceduti': '#212121',
      'totale_casi': ''
    }
  },
  mutations: {
    setNationalTrend(state: any, payload: any) {
      state.nationalTrend = payload
    },
    setRegionalTrend(state: any, payload: any) {
      const regionNames = new Set<string>()
      payload.map((element: any) => {
        regionNames.add(element['denominazione_regione'])
      })
      state.regionNames = Array.from(regionNames.values())

      const wantedRegionTrendKeys = [
        'ricoverati_con_sintomi',
        'terapia_intensiva',
        'totale_ospedalizzati',
        'isolamento_domiciliare',
        'totale_positivi',
        'nuovi_attualmente_positivi',
        'dimessi_guariti',
        'deceduti',
        'totale_casi',
        'tamponi',
        'data'
      ]

      const regionalTrend: {[key: string]: {[key: string]: any[]}} = {}

      state.regionNames.map((region: string) => {
        regionalTrend[region] = {}
        wantedRegionTrendKeys.map((key: string) => {
          regionalTrend[region][key]=[]
        })
      })

      payload.map((regionEntry: any) => {
        wantedRegionTrendKeys.map((key: string) => {
          regionalTrend[regionEntry['denominazione_regione']][key].push(regionEntry[key])
        })
      })

      state.regionalTrend = regionalTrend

    },
    setProvincialData(state: any, payload: any) {
      state.provincialData = payload
    },
    setWorldData(state: any, payload: any) {
      const worldData = Papa.parse(payload, {
        header:true,
        skipEmptyLines: true
      })
      state.worldData = worldData.data
    },
  },
  actions: {
    loadNationalTrend(store: any, payload: any) {
      const nationalTrendURL = GITHUB_ITALIAN_DATA_BASE_URL + 'dati-json/dpc-covid19-ita-andamento-nazionale.json'
      axios.get(nationalTrendURL).then((res: AxiosResponse) => {
        store.commit('setNationalTrend', res.data)
      })
    },
    loadRegionalTrend(store: any, payload: any) {
      const regionalTrendURL = GITHUB_ITALIAN_DATA_BASE_URL + 'dati-json/dpc-covid19-ita-regioni.json'
      axios.get(regionalTrendURL).then((res: AxiosResponse) => {
        store.commit('setRegionalTrend', res.data)
      })
    },
    loadProvincialData(store: any, payload: any) {
      const provincialTrendURL = GITHUB_ITALIAN_DATA_BASE_URL + 'dati-json/dpc-covid19-ita-province-latest.json'
      axios.get(provincialTrendURL).then((res: AxiosResponse) => {
        store.commit('setProvincialData', res.data)
      })
    },
    loadWorldData(store: any, payload: any) {
      const worldTrendURL = GITHUB_WORLD_DATA_BASE_URL +
          'csse_covid_19_data/csse_covid_19_time_series/time_series_covid19_confirmed_global.csv'
      axios.get(worldTrendURL).then((res: AxiosResponse) => {
        store.commit('setWorldData', res.data)
      })
    }
  },
  getters: {
    getNationalTrend(state: any): any {
      return state.nationalTrend
    },
    getRegionalTrend(state: any): any {
      return state.regionalTrend
    },
    getRegionNames(state: any): any {
      return state.regionNames
    },
    getProvincialData(state: any): any {
      return state.provincialData
    },
    getWorldData(state: any): any {
      return state.worldData
    }
  },
  modules: {
  }
})
