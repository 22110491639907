




import Vue from 'vue'
import Component from 'vue-class-component';

import * as echarts from 'echarts'
import ECharts = echarts.ECharts;
import {Prop, Watch} from 'vue-property-decorator';
import Series = echarts.EChartOption.Series;

import ChartUtils from '@/components/charts/ChartUtils';
import YAxis = echarts.EChartOption.YAxis;

@Component
export default class NationComparisonChart extends Vue{

    private trendsChart!: ECharts;

    @Prop()
    private xAxisLabels!: string[]

    @Prop()
    private yAxisType!: string

    @Prop()
    private inputData!: {[key: string]: string | number}[]

    @Prop()
    private chartId!: string

    @Prop()
    private serieColors!: string[]

    private axisScale: YAxis = {
        type: 'log'
    } as YAxis


    @Watch('inputData')
    private onDataChange() {
        this.initChart()
    }

    @Watch('yAxisType')
    private onAxisScaleChange() {
        this.axisScale.type = this.yAxisType === "Scala Logaritmica"
            ? 'log'
            : 'value'
        this.initChart()
    }

    public mounted() {
        const chartDiv = document.getElementById(this.chartId) as HTMLDivElement
        this.trendsChart = echarts.init(chartDiv)
        this.initChart()
        return 0
    }

    private initChart() {

        const chartSeries: Series[] = []

        const legendData: string[] = []

        this.inputData.map((entry) => {
            legendData.push(entry['country'] as string)
            chartSeries.push({
                symbol: 'none',
                type: 'line',
                data: entry['data'] || [],
                name: entry['country'],
                lineStyle: {
                    width: ChartUtils.getLineWidth
                }
            } as Series)
        })

        this.trendsChart.setOption({
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: legendData
            },
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: this.xAxisLabels
            },
            yAxis: this.axisScale,
            color: this.serieColors,
            series: chartSeries
        })

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        window.addEventListener('resize', function() {
            vm.trendsChart.resize()
        })
    }
}
