










import Vue from 'vue'
import NavBar from '@/components/NavBar.vue'
import Component from 'vue-class-component'

@Component({components:{NavBar}})
export default class App extends Vue{
}
